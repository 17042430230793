<template>
  <section id="policy-section-container">
    <h1>Personal Data Protection Policy</h1>
    <p>
      Alpha Global Wellness is committed to protecting the personal data that
      you disclosed to us in accordance with the Personal Data Protection Act
      2012. We will not disclose or sell your personal data to third parties for
      their marketing purposes without your consent. Please read this Personal
      Data Protection Policy (“Policy”) to learn about the ways in which we
      collect, use and disclose your personal data.<br /><br />
      This Policy helps you understand our practices on the collection, use and
      disclosure of personal data so that you can make informed decisions when
      using this website. By using this website, you will be deemed to have
      consented to our collection, use and disclosure of your personal data in
      accordance with this Policy. If you do not agree, please do not continue
      to use or access this website
    </p>
    <ol>
      <li>
        <h2>Information We Collect</h2>
        <p>
          Our purpose in collecting personal data is to provide an experience
          that is customized and responsive to your needs. We only collect
          personal data about you that we consider necessary to achieve this
          purpose.<br /><br />
          If you choose to use the services or feature of this website, we may
          require you to provide your contact details and other personal
          information as indicated in the relevant page. We will indicate which
          fields are mandatory and which fields are optional. You will always
          have the option not to provide the information requested by choosing
          not to use a particular service or feature.<br /><br />
          We may require you to provide your financial information, including
          credit card details, to verify the accuracy of your name, address and
          other relevant information in order to bill you for your use of our
          services.
        </p>
      </li>
      <li>
        <h2>Use of Your Information</h2>
        <p>
          We use your personal data to facilitate the services you requested. We
          also use your personal data with other information that we have
          collected from your current and past activities on the website to:
          attend to your queries and request; troubleshoot technical problems;
          secure a safer online transaction; collect payments; measure consumer
          interest in our products and services; inform you about our offers,
          latest products and workshop update; customize your experience; detect
          and protect us against errors, fraud, and other criminal activity. If
          necessary, we may look across multiple users to identify problems or
          resolve disputes, and in particular we may examine your personal data
          to identify user using multiple user names or aliases. We may compare
          and review your personal data for errors, omissions and accuracy.<br /><br />
          You agree that we may use your personal data for the aforesaid
          purposes which are intended to improve our promotional efforts,
          analyze site usage, improve our content and product offerings,
          customize our online content, layout and services. Such use of your
          personal data will enable us to constantly improve our website
          services to meet your needs.<br /><br />
          You agree that we may also use your personal data to contact you and
          deliver information to you that in some cases, are targeted to your
          interests, such as administrative notices, product offerings, workshop
          updates, and communications relevant to your use of the website.
        </p>
      </li>
      <li>
        <h2>Disclosure of Your Information</h2>
        <p>
          We will not disclose or sell any of your personal data to third
          parties for their marketing purposes without your consent. However,
          the following describe some of the ways information may be disclosed
          in the course of business with third parties in the provision of our
          services.<br /><br />
          Internal Service Providers: We may use third parties to facilitate or
          outsource one or more aspects of the business, product and service
          operations that we provide to you (eg. search technology, payment
          collection, reward and credit card programs) and therefore we may
          provide necessary personal data to these internal service providers.
          These internal service providers are subject to confidentiality and
          data protection obligations and other legal restrictions that prohibit
          them from using the information we provide them for any other purposes
          except to facilitate the specific outsourced operation. In some
          instances, the internal service providers may collect information
          directly from you, you will be notified of the involvement of the
          internal service provider, and all information you provide to them and
          their use of the information will be strictly between you and the
          internal service provider. If you provide personal data directly to an
          internal service provider, their use of the personal data provided by
          you will be governed by their applicable privacy policy.<br /><br />
          Other Corporate Entities: We share our data, including your personal
          data, with our related companies and subsidiaries that are committed
          to serving your online transactions and related services. To the
          extent that these entities have access to your personal data, they
          will be required to treat your personal data with the same level of
          protection as we treat your personal data.
        </p>
      </li>
      <li>
        <h2>Security of Personal Data</h2>
        <p>
          We will take reasonable steps to protect personal data in our
          possession or under our control by making reasonable security
          arrangements to prevent unauthorized access, collection, use and
          disclosure of these personal data.
        </p>
      </li>
      <li>
        <h2>Links To Other Websites</h2>
        <p>
          Our website and online confirmation emails may contain links to other
          websites whose data protection and privacy practices may differ from
          ours. We are not responsible for the content and privacy policies of
          these other companies and we encourage you to review their privacy
          policies before using their websites.
        </p>
      </li>
      <li>
        <h2>Personal Data Protection Policy Update</h2>
        <p>
          We reserve the right to update this Policy from time to time as we
          expand our services. If so, we will post a notice on our website to
          inform you of the updated Policy. Your use of the website after this
          Policy has been updated will be deemed as your acceptance of the
          updated Policy
        </p>
      </li>
      <li>
        <h2>Contact Us</h2>
        <p>
          For any queries, please attention your email to our Data Protection
          Officer at
          <a href="mailto:enquiries@alpgw.com">enquiries@alpgw.com</a>
          <br /><br />
          By providing to us your personal data, you declare that you have read,
          understood and accepted the provisions herein, and do hereby consent
          to the processing of your personal data in accordance with the
          foregoing. You acknowledge that the use or continued use of our
          website services shall be deemed as your agreement and consent to be
          bound by our Personal Data Protection Policy.
        </p>
      </li>
    </ol>
    <br />
  </section>
</template>

<script>
export default {
  name: "PrivacyPolicy",
};
</script>

<style lang="scss" scoped>
#policy-section-container {
  background-color: #aec99c;
  text-align: left;

  ol {
    padding-inline-start: 15px;

    li {
      font-size: 1.5rem;
    }
    h2 {
      font-size: 1.5rem;
    }
    p {
      font-size: 1rem;
    }
  }
}
</style>
